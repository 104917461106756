import type { ChatMessage, ChatResponse, SendMessageRequest } from "../types/chat.ts";

// This can be configured based on environment
const API_URL = 'http://localhost:8001/api/chat';

export async function sendMessage(request: SendMessageRequest): Promise<ChatResponse> {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    
    if (!response.ok) {
      throw new Error('Failed to send message');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Chat service error:', error);
    return {
      message: {
        id: 'error',
        content: 'Failed to send message. Please try again.',
        sender: 'assistant',
        timestamp: new Date().toISOString(),
      },
      error: error.message,
    };
  }
}

export async function getChatHistory(conversationId: string): Promise<ChatMessage[]> {
  try {
    const response = await fetch(`${API_URL}/${conversationId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch chat history');
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch chat history:', error);
    return [];
  }
}
